<template>
    <div class="d-flex align-items-center text-nowrap">
        <template v-if="columnData.indexOf('tracker-startup') >= 0">
            <div class="card flex-row">
                <b-dropdown class="ml-5 drag-n-drop icons-list dropdown-simple dropdown-small" boundary="viewport">
                    <b-dropdown-item class="icons-list__pdf" @click="$emit('action', {action: 'pdf', rowId: rowId})">
                        Export PDF
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </template>

        <template v-if="columnData.indexOf('ecosystem-startup') >= 0">
            <div class="card flex-row">
                <b-dropdown class="ml-5 drag-n-drop icons-list dropdown-simple dropdown-small" boundary="viewport">
                    <b-dropdown-item class="icons-list__delete" @click="$emit('action', {action: 'delete', rowId: rowId})">
                        Remove Startup
                    </b-dropdown-item>
                    <b-dropdown-item class="icons-list__delete" @click="$emit('action', {action: 'add-to-portfolio', rowId: rowId})">
                        Add to a portfolio
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </template>

        <template v-else-if="columnData.indexOf('tracker-submission') >= 0">
            <a class="link" @click="$emit('action', {action: 'view-answers', rowId: rowId})">View Answers</a>
        </template>

        <template v-else-if="columnData.indexOf('portfolio-startups') >= 0">
            <div class="card flex-row">
                <b-dropdown class="ml-5 drag-n-drop icons-list dropdown-simple dropdown-small" boundary="viewport">
                    <b-dropdown-item class="" @click="$emit('action', {action: 'add-to-tracker', rowId: rowId})">
                        Add to tracker
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </template>

        <template v-else-if="columnData.indexOf('task') >= 0">
            <div class="drag-n-drop-wrapper"> <!--flex-row ml-auto-->
                <b-dropdown class="drag-n-drop icons-list dropdown-simple dropdown-small" boundary="viewport">
                    <b-dropdown-item class="icons-list__edit" @click="$emit('action', {action: 'edit', rowId: rowId})">
                        Edit Task
                    </b-dropdown-item>
                    <b-dropdown-item class="icons-list__delete" @click="$emit('action', {action: 'delete', rowId: rowId})">
                        <font-awesome-icon :icon="['fa', 'trash']" />
                        Remove Task
                    </b-dropdown-item>
                </b-dropdown>
            </div>
        </template>

        <template v-else>
            <div v-if="columnData.indexOf('edit') >= 0">
                <a @click="$emit('action', {action: 'edit', rowId: rowId})" class="link-edit link-edit--secondary">Edit</a>
            </div>

            <div v-if="columnData.indexOf('relations') >= 0">
                <a @click="$emit('action', {action: 'relations', rowId: rowId})" class="link-relations ml-2">Relations</a>
            </div>

            <div v-if="columnData.indexOf('sync-cb') >= 0">
                <a @click="$emit('action', {action: 'sync-cb', rowId: rowId})" class="link-sync ml-2">Sync CB</a>
            </div>

            <div v-if="columnData.indexOf('sync-kg') >= 0">
                <a @click="$emit('action', {action: 'sync-kg', rowId: rowId})" class="link-sync ml-2">Sync KG</a>
            </div>

            <div v-if="columnData.indexOf('sync-ppx') >= 0">
                <a @click="$emit('action', {action: 'sync-ppx', rowId: rowId})" class="link-sync ml-2">Sync PPX</a>
            </div>

            <div v-if="columnData.indexOf('sync-elastic') >= 0">
                <a @click="$emit('action', {action: 'sync-elastic', rowId: rowId})" class="link-sync ml-2">Sync Elastic</a>
            </div>

            <div v-if="columnData.indexOf('delete') >= 0">
              <a @click="$emit('action', {action: 'delete', rowId: rowId})" class="link-delete link-delete--text ml-2 w-auto"><font-awesome-icon :icon="['fa', 'trash']" />Delete</a>
            </div>

            <div v-if="columnData.indexOf('merge') >= 0">
                <a @click="$emit('action', {action: 'merge', rowId: rowId})" class="link ml-2">Merge</a>
            </div>

            <div v-if="columnData.indexOf('share-portfolio') >= 0">
                <a @click="$emit('action', {action: 'share', rowId: rowId})" class="link-share ml-3">Share Portfolio</a>
            </div>

            <div v-if="columnData.indexOf('connect') >= 0">
                <a @click="$emit('action', {action: 'connect', rowId: rowId})" class="btn btn-primary">Connect</a>
            </div>

            <div v-if="columnData.indexOf('edit-funding-rounds') >= 0">
                <a @click="$emit('action', {action: 'edit-funding-rounds', rowId: rowId})" class="link-edit link-edit--secondary ml-2">Edit Funding Rounds</a>
            </div>

            <div v-if="columnData.indexOf('crawl') >= 0">
                <a @click="$emit('action', {action: 'crawl', rowId: rowId})" class="link-sync ml-2">Crawl</a>
            </div>

            <div v-if="columnData.indexOf('check-job-status') >= 0">
                <a @click="$emit('action', {action: 'check-job-status', rowId: rowId})" class="link-sync ml-2">Check job status</a>
            </div>

            <div v-if="columnData.indexOf('remove-job') >= 0">
                <a @click="$emit('action', {action: 'remove-job', rowId: rowId})" class="link-delete link-delete--text w-auto ml-2"><font-awesome-icon :icon="['fa', 'trash']" />Remove job</a>
            </div>

            <div v-if="columnData.indexOf('download-crawl-json') >= 0">
                <a @click="$emit('action', {action: 'download-crawl-json', rowId: rowId})" class="ml-2">Download JSON</a>
            </div>

            <div v-if="columnData.indexOf('show-crawled-data') >= 0">
                <a @click="$emit('action', {action: 'show-crawled-data', rowId: rowId})" class="ml-2">Crawled Data</a>
            </div>

            <div v-if="columnData.indexOf('translate') >= 0">
                <a @click="$emit('action', {action: 'translate', rowId: rowId})" class="ml-2">Translate</a>
            </div>

            <div v-if="columnData.indexOf('users') >= 0">
                <a @click="$emit('action', {action: 'users', rowId: rowId})" class="link-edit link-edit--secondary ml-2">Users</a>
            </div>

            <div v-if="columnData.indexOf('open-as-user') >= 0">
                <a @click="$emit('action', {action: 'users', rowId: rowId})" class="link-share link-edit--secondary ml-2">Open as User</a>
            </div>

            <div v-if="columnData.indexOf('invite') >= 0">
                <a @click="$emit('action', {action: 'invite', rowId: rowId})" class="link-invite ml-2 w-auto"><font-awesome-icon :icon="['fa', 'user-plus']" />Invite</a>
            </div>

            <div class="ml-4" v-if="columnData.indexOf('send-activation-email') >= 0">
                <a @click="$emit('action', {action: 'send-activation-email', rowId: rowId})">Re-send Invite</a>
            </div>

            <div v-if="columnData.indexOf('add-hours-spent') >= 0">
                <a @click="$emit('action', {action: 'add-hours-spent', rowId: rowId})" class="ml-2">Log time spent</a>
            </div>

            <div v-if="columnData.indexOf('add-hours') >= 0">
                <a @click="$emit('action', {action: 'add-hours', rowId: rowId})" class="ml-2">Add hours</a>
            </div>

            <div v-if="columnData.indexOf('contacts') >= 0">
                <a @click="$emit('action', {action: 'contacts', rowId: rowId})" class="link-relations ml-2">Contacts</a>
            </div>

            <div v-if="columnData.indexOf('map-categories') >= 0">
                <a @click="$emit('action', {action: 'map-categories', rowId: rowId})" class="link-relations ml-2">Map Categories</a>
            </div>

            <div v-if="columnData.indexOf('impersonate') >= 0">
                <a @click="$emit('action', {action: 'impersonate', rowId: rowId})">Impersonate</a>
            </div>

            <div v-if="columnData.includes('admin-export-enterprises')">
                <b-dropdown text="Export" class="dropdown--export">
                    <b-dropdown-item @click="$emit('action', {action: 'export-custom-values', rowId: rowId})">
                        Export custom values
                    </b-dropdown-item>

                    <b-dropdown-item @click="$emit('action', {action: 'export-startups-stages', rowId: rowId})">
                        Export startups stages
                    </b-dropdown-item>
                </b-dropdown>
            </div>

            <div v-for="(action, index) in columnData" :key="index">
                <template v-if="typeof(action) === 'object'">
                    <a @click="$emit('action', {action: action.name, rowId: rowId})" :class="action.class">{{action.label}}</a>
                </template>
            </div>

        </template>
    </div>
</template>

<script>
export default {
    props: {
        rowId: Number,
        columnData: Array,
    },
}
</script>

<style scoped>

</style>
